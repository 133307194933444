import { AdminBreadcrumbItem } from './admin-breadcrumb.types';
import { Link } from '../link/link';
import { classNames } from '../../helpers/classNames';
import { LinkExternal } from '../link/link-external';
import { App } from '../../../common/types/app.types';
import { getNavAppUrl } from '../../../common/helpers/apps.helpers';
import { getNavAppConfig } from '../nav/nav.helpers';
import { getMultiClientPlatformConfigForClient } from '../../helpers/multi-client-config.helpers';

interface Props {
  project: App;
  items: AdminBreadcrumbItem[];
}

export const AdminBreadcrumb = ({ items, project }: Props) => {
  const config = getMultiClientPlatformConfigForClient();

  return (
    <nav className="mb-psc-base flex text-sm" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-psc-sm">
        <li>
          <div>
            <LinkExternal href={window.env.PLATFORM_URL}>Home</LinkExternal>
          </div>
        </li>
        {project === App.enum.fun &&
          config.fun?.brands?.map((brand, i) => (
            <li key={`breadcrumb-${brand}-${i}`}>
              <div>
                <i className="fa fa-chevron-right fa-xs flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link className="ml-psc-sm font-medium" to={`${getNavAppUrl(project)}/${brand}`}>
                  &nbsp;FUN Dashboard
                </Link>
              </div>
            </li>
          ))}
        {project !== App.enum.platform && project !== App.enum.chat && (
          <li>
            <div className="flex items-center">
              <i className="fa fa-chevron-right fa-xs flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link className="ml-psc-sm font-medium" to={`${getNavAppUrl(project)}/admin`}>
                {getNavAppConfig(project).appName} Admin
              </Link>
            </div>
          </li>
        )}
        {items.map((item, i) => (
          <li key={`breadcrumb-${i}`}>
            <div className="flex items-center">
              <i className="fa fa-chevron-right fa-xs flex-shrink-0 text-gray-400" aria-hidden="true" />
              {item.to ? (
                <Link to={item.to} className="ml-psc-sm font-medium" aria-current={item.active ? 'page' : undefined}>
                  {item.name}
                </Link>
              ) : (
                <span
                  className={classNames('ml-psc-sm font-medium', item.active ? 'text-gray-700' : 'text-gray-600')}
                  aria-current={item.active ? 'page' : undefined}
                >
                  {item.name}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
